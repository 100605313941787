/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum {
    Month = 'month',
    FiscalYear = 'fiscal_year',
    Dpc6Digits = 'dpc_6_digits',
    OwnDepartmentIds = 'own_department_ids',
    CommunicationType = 'communication_type',
    TenantMedicalInstitutionIds = 'tenant_medical_institution_ids',
    IsDoctorAttendedVisit = 'is_doctor_attended_visit',
    CommunicationLabels = 'communication_labels',
    Period = 'period',
    RevenueTypes = 'revenue_types',
    MedicalInstitutionId = 'medical_institution_id',
    MhlwDesignationReason = 'mhlw_designation_reason',
    MhlwDesignationProcessedMonth = 'mhlw_designation_processed_month',
    VocStatuses = 'voc_statuses',
    VocGroupIds = 'voc_group_ids',
    VocPriorities = 'voc_priorities',
    IncludeNullVocGroup = 'include_null_voc_group',
    IncludeNullVocPriority = 'include_null_voc_priority'
}

export function MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSON(json: any): MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum {
    return MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum {
    return json as MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum;
}

export function MedicalInstitutionReportItemDefinitionEnabledAggregationsEnumToJSON(value?: MedicalInstitutionReportItemDefinitionEnabledAggregationsEnum | null): any {
    return value as any;
}

