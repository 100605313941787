/* tslint:disable */
/* eslint-disable */
/**
 * foroCRM API
 * foroCRM で利用するAPIスキーマです！
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
} from './CommunicationType';
import {
    MedicalInstitutionReportItemAggregationConditionCommunicationLabel,
    MedicalInstitutionReportItemAggregationConditionCommunicationLabelFromJSON,
    MedicalInstitutionReportItemAggregationConditionCommunicationLabelFromJSONTyped,
    MedicalInstitutionReportItemAggregationConditionCommunicationLabelToJSON,
} from './MedicalInstitutionReportItemAggregationConditionCommunicationLabel';
import {
    StandardRevenueTypeEnum,
    StandardRevenueTypeEnumFromJSON,
    StandardRevenueTypeEnumFromJSONTyped,
    StandardRevenueTypeEnumToJSON,
} from './StandardRevenueTypeEnum';
import {
    VocPriorityEnum,
    VocPriorityEnumFromJSON,
    VocPriorityEnumFromJSONTyped,
    VocPriorityEnumToJSON,
} from './VocPriorityEnum';
import {
    VocStatusEnum,
    VocStatusEnumFromJSON,
    VocStatusEnumFromJSONTyped,
    VocStatusEnumToJSON,
} from './VocStatusEnum';

/**
 * 連携先レポート項目集計条件
 * @export
 * @interface MedicalInstitutionReportItemAggregationCondition
 */
export interface MedicalInstitutionReportItemAggregationCondition {
    /**
     * 集計範囲日付の開始月。当月含む。指定がない場合、month_end以前すべての範囲を集計します
     * @type {string}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    startMonth?: string;
    /**
     * 集計範囲日付の終端月。当月含む。指定がない場合、month_begin以後すべての範囲を集計します
     * @type {string}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    endMonth?: string;
    /**
     * 現在からの相対期間指定条件の始端。正数は未来（Nカ月後）、負数は過去（Mカ月前）。
     * @type {number}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    startMonthsAway?: number;
    /**
     * 現在からの相対期間指定条件の終端。正数は未来（Nカ月後）、負数は過去（Mカ月前）。
     * @type {number}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    endMonthsAway?: number;
    /**
     * 集計対象とする自院の診療科ID。指定がない場合、全ての診療科を集計します
     * @type {Array<number>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    ownDepartmentIds?: Array<number>;
    /**
     * 集計する日付範囲を年度で指定する。(選択年の04/01~翌年の03/31)
     * @type {string}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    fiscalYear?: string;
    /**
     * 現在からの相対年度指定条件。正数は未来（Nカ年度後）、負数は過去（Mカ年度前）。
     * @type {number}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    fiscalYearsAway?: number;
    /**
     * 期間を判別する文字列。連携先セグメントで利用
     * @type {string}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    period?: string;
    /**
     * 現在からの相対半期年度の指定条件。正数は未来（N半期後）、負数は過去（M半期前）。たとえば、現在が 2022-04 (2022上期) で -1 を指定した場合、2021下期が対象となる
     * @type {number}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    halfYearsAway?: number;
    /**
     * 
     * @type {CommunicationType}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    communicationType?: CommunicationType;
    /**
     * 集計対象とする自グループ病院の医療機関ID。指定がない場合、グループ内全ての病院を対象に集計します
     * @type {Array<number>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    tenantMedicalInstitutionIds?: Array<number>;
    /**
     * 医師面談実施の有無 (訪問のみ)。
     * @type {boolean}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    isDoctorAttendedVisit?: boolean;
    /**
     * 連携先レポート項目集計条件で利用するコミュニケーションラベルの配列
     * @type {Array<MedicalInstitutionReportItemAggregationConditionCommunicationLabel>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    communicationLabels?: Array<MedicalInstitutionReportItemAggregationConditionCommunicationLabel>;
    /**
     * 集計対象とするDPC6桁の配列
     * @type {Array<string>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    dpc6Digits?: Array<string>;
    /**
     * 集計対象とする標準収益種別の配列
     * @type {Array<StandardRevenueTypeEnum>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    revenueTypes?: Array<StandardRevenueTypeEnum>;
    /**
     * 「近隣医療機関との距離」項目で参照する先の医療機関ID
     * @type {number}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    medicalInstitutionId?: number;
    /**
     * 保険医療機関の届出（登録理由別の日付）の登録理由
     * @type {string}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    mhlwDesignationReason?: string;
    /**
     * 保険医療機関の届出（処理月別の登録理由）の処理月
     * @type {string}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    mhlwDesignationProcessedMonth?: string;
    /**
     * 集計対象とする連携先フィードバックステータスの配列
     * @type {Array<VocStatusEnum>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    vocStatuses?: Array<VocStatusEnum>;
    /**
     * 集計対象とする連携先フィードバックグループのIDの配列 (uuid)
     * @type {Array<string>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    vocGroupIds?: Array<string>;
    /**
     * 未設定のフィードバックグループを含めるかどうか
     * @type {boolean}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    includeNullVocGroup?: boolean;
    /**
     * 集計対象とする連携先フィードバック優先度の配列
     * @type {Array<VocPriorityEnum>}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    vocPriorities?: Array<VocPriorityEnum>;
    /**
     * 未設定のフィードバック優先度を含めるかどうか
     * @type {boolean}
     * @memberof MedicalInstitutionReportItemAggregationCondition
     */
    includeNullVocPriority?: boolean;
}

export function MedicalInstitutionReportItemAggregationConditionFromJSON(json: any): MedicalInstitutionReportItemAggregationCondition {
    return MedicalInstitutionReportItemAggregationConditionFromJSONTyped(json, false);
}

export function MedicalInstitutionReportItemAggregationConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalInstitutionReportItemAggregationCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startMonth': !exists(json, 'start_month') ? undefined : json['start_month'],
        'endMonth': !exists(json, 'end_month') ? undefined : json['end_month'],
        'startMonthsAway': !exists(json, 'start_months_away') ? undefined : json['start_months_away'],
        'endMonthsAway': !exists(json, 'end_months_away') ? undefined : json['end_months_away'],
        'ownDepartmentIds': !exists(json, 'own_department_ids') ? undefined : json['own_department_ids'],
        'fiscalYear': !exists(json, 'fiscal_year') ? undefined : json['fiscal_year'],
        'fiscalYearsAway': !exists(json, 'fiscal_years_away') ? undefined : json['fiscal_years_away'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'halfYearsAway': !exists(json, 'half_years_away') ? undefined : json['half_years_away'],
        'communicationType': !exists(json, 'communication_type') ? undefined : CommunicationTypeFromJSON(json['communication_type']),
        'tenantMedicalInstitutionIds': !exists(json, 'tenant_medical_institution_ids') ? undefined : json['tenant_medical_institution_ids'],
        'isDoctorAttendedVisit': !exists(json, 'is_doctor_attended_visit') ? undefined : json['is_doctor_attended_visit'],
        'communicationLabels': !exists(json, 'communication_labels') ? undefined : ((json['communication_labels'] as Array<any>).map(MedicalInstitutionReportItemAggregationConditionCommunicationLabelFromJSON)),
        'dpc6Digits': !exists(json, 'dpc_6_digits') ? undefined : json['dpc_6_digits'],
        'revenueTypes': !exists(json, 'revenue_types') ? undefined : ((json['revenue_types'] as Array<any>).map(StandardRevenueTypeEnumFromJSON)),
        'medicalInstitutionId': !exists(json, 'medical_institution_id') ? undefined : json['medical_institution_id'],
        'mhlwDesignationReason': !exists(json, 'mhlw_designation_reason') ? undefined : json['mhlw_designation_reason'],
        'mhlwDesignationProcessedMonth': !exists(json, 'mhlw_designation_processed_month') ? undefined : json['mhlw_designation_processed_month'],
        'vocStatuses': !exists(json, 'voc_statuses') ? undefined : ((json['voc_statuses'] as Array<any>).map(VocStatusEnumFromJSON)),
        'vocGroupIds': !exists(json, 'voc_group_ids') ? undefined : json['voc_group_ids'],
        'includeNullVocGroup': !exists(json, 'include_null_voc_group') ? undefined : json['include_null_voc_group'],
        'vocPriorities': !exists(json, 'voc_priorities') ? undefined : ((json['voc_priorities'] as Array<any>).map(VocPriorityEnumFromJSON)),
        'includeNullVocPriority': !exists(json, 'include_null_voc_priority') ? undefined : json['include_null_voc_priority'],
    };
}

export function MedicalInstitutionReportItemAggregationConditionToJSON(value?: MedicalInstitutionReportItemAggregationCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_month': value.startMonth,
        'end_month': value.endMonth,
        'start_months_away': value.startMonthsAway,
        'end_months_away': value.endMonthsAway,
        'own_department_ids': value.ownDepartmentIds,
        'fiscal_year': value.fiscalYear,
        'fiscal_years_away': value.fiscalYearsAway,
        'period': value.period,
        'half_years_away': value.halfYearsAway,
        'communication_type': CommunicationTypeToJSON(value.communicationType),
        'tenant_medical_institution_ids': value.tenantMedicalInstitutionIds,
        'is_doctor_attended_visit': value.isDoctorAttendedVisit,
        'communication_labels': value.communicationLabels === undefined ? undefined : ((value.communicationLabels as Array<any>).map(MedicalInstitutionReportItemAggregationConditionCommunicationLabelToJSON)),
        'dpc_6_digits': value.dpc6Digits,
        'revenue_types': value.revenueTypes === undefined ? undefined : ((value.revenueTypes as Array<any>).map(StandardRevenueTypeEnumToJSON)),
        'medical_institution_id': value.medicalInstitutionId,
        'mhlw_designation_reason': value.mhlwDesignationReason,
        'mhlw_designation_processed_month': value.mhlwDesignationProcessedMonth,
        'voc_statuses': value.vocStatuses === undefined ? undefined : ((value.vocStatuses as Array<any>).map(VocStatusEnumToJSON)),
        'voc_group_ids': value.vocGroupIds,
        'include_null_voc_group': value.includeNullVocGroup,
        'voc_priorities': value.vocPriorities === undefined ? undefined : ((value.vocPriorities as Array<any>).map(VocPriorityEnumToJSON)),
        'include_null_voc_priority': value.includeNullVocPriority,
    };
}

